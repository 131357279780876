import {displayStatus} from '@/context/StatusContext';

type CopyFn = (text: string) => Promise<boolean>;

async function copyToClipboard(text: string): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
}

export async function copyToClipboardWithStatusContext(text: string): Promise<boolean> {
  const result = await copyToClipboard(text);
  if (result) {
    displayStatus({
      type: 'info',
      content: 'Copied to clipboard!',
    });
  } else {
    displayStatus({
      type: 'error',
      content: 'Unable to copy to clipboard. Check browser permissions?',
    });
  }
  return result;
}
