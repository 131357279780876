import SwitchInput from '@/components/form/SwitchInput';
import TextInput from '@/components/form/TextInput';
import type {PopoverPanel} from '@headlessui/react';
import type {Params} from '@remix-run/router';
import type {ComponentProps} from 'react';
import type {ZodTypeAny} from 'zod';

export type OnInputChange<V> = (inputValue: InputValue<V>) => void;

export type FormSpec = {
  inputSpecs: InputSpec<any>[];
  onSubmit(
    values: Record<string, any>,
    options: {
      params: Readonly<Params<string>>;
      searchParams: URLSearchParams;
    },
  ): void | Promise<void | {ignoreFormReset: true}>;
  submitText?: string;
  submitButtonFullWidth?: boolean;
};

export const InputComponentsByType = {
  text: TextInput,
  password: TextInput,
  switch: SwitchInput,
};

export type InputType = keyof typeof InputComponentsByType;

export type InputSpec<V> = {
  name: HTMLInputElement['name'];
  label: string;
  description?: string;
  required?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  disabledTooltipAnchor?: ComponentProps<typeof PopoverPanel>['anchor'];
  validator?: ZodTypeAny;
  defaultValue?: V;
} & (
  | {
      type: 'text' | 'password';
      buttonText?: string;
      autoComplete?: HTMLInputElement['autocomplete'];
      spellCheck?: HTMLInputElement['spellcheck'];
      autoCapitalize?: HTMLInputElement['autocapitalize'];
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
      IconComponent?: React.ForwardRefExoticComponent<
        React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>
      >;
    }
  | {
      type: 'switch';
    }
);

export type InputValue<V> = {
  name: string;
  value: V;
  errors: string[];
  hasChanged: boolean;
  hasInteracted: boolean;
};
