import {viewportDimensionsProxy} from '@/state/ViewportStore';
import {copyToClipboardWithStatusContext} from '@/utils/clipboard';
import {useValtioSnapshot} from '@/utils/valtio';
import {Popover, PopoverButton, PopoverPanel, Transition} from '@headlessui/react';
import clsx from 'clsx';
import React, {useCallback, useRef, type ComponentProps} from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
  content: React.ReactNode;
  anchor?: ComponentProps<typeof PopoverPanel>['anchor'];
};

export const LivanPopover = React.memo(function LivanPopover(props: Props) {
  const {className, children, content, anchor} = props;
  const triggerRef = useRef<HTMLButtonElement>(null);

  const viewportDimensions = useValtioSnapshot(viewportDimensionsProxy);

  const anchorObj: ComponentProps<typeof PopoverPanel>['anchor'] = {
    gap: 10,
  };
  if (typeof anchor === 'string') {
    anchorObj.to = anchor;
  } else {
    Object.assign(anchorObj, anchor);
  }

  if (
    !viewportDimensions.md &&
    (anchorObj.to?.includes('left') || anchorObj.to?.includes('right'))
  ) {
    // override this on small viewports so that tooltips don't overflow and get scrunched
    anchorObj.to = 'bottom';
  }

  const handleEnter = (isOpen) => {
    if (!isOpen) {
      triggerRef.current?.click();
    }
  };

  const handleLeave = (isOpen) => {
    if (isOpen) {
      triggerRef.current?.click();
    }
  };
  const handlePanelClick = useCallback(
    function () {
      if (typeof content === 'string') {
        copyToClipboardWithStatusContext(content);
      }
    },
    [content],
  );

  return (
    <Popover as={React.Fragment}>
      {({open}) => (
        <React.Fragment>
          <PopoverButton
            className={className}
            ref={triggerRef}
            onMouseEnter={() => handleEnter(open)}
            onMouseLeave={() => handleLeave(open)}
          >
            {children}
          </PopoverButton>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel
              className={clsx(
                'absolute z-50 px-2 py-1 bg-livan-black text-livan-white rounded-md text-sm !max-w-64',
                typeof content === 'string' && 'cursor-pointer',
              )}
              anchor={anchorObj}
              portal
              onClick={typeof content === 'string' ? handlePanelClick : undefined}
            >
              {content}
            </PopoverPanel>
          </Transition>
        </React.Fragment>
      )}
    </Popover>
  );
});
