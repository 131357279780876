import Button from '@/components/core/Button';
import type {FormInputRef} from '@/components/form/FormInput';
import {
  InputComponentsByType,
  type FormSpec,
  type InputValue,
  type InputSpec,
  type OnInputChange,
} from '@/components/form/FormTypes';
import {openModal} from '@/components/modal/ModalContext';
import type {BaseComponentProps} from '@/components/types';
import {requestAnimationFrameAsync} from '@/utils/animation';
import assert from '@/utils/assert';
import {constructErrorMeta, displayClientError} from '@/utils/errors';
import {eventIsSubmit} from '@/utils/keyboard';
import {updateKeyOnSignalIfChanged} from '@/utils/signals';
import {stopPropagationAndPreventDefault} from '@/utils/stopPropagationAndPreventDefault';
import type {Signal} from '@preact/signals-react';
import {batch, computed, useSignal} from '@preact/signals-react';
import {useSignals} from '@preact/signals-react/runtime';
import {useBlocker, useParams, useSearchParams} from '@remix-run/react';
import clsx from 'clsx';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  type FC,
  type FormEventHandler,
  type ForwardedRef,
  type KeyboardEventHandler,
  type ReactNode,
  type RefCallback,
} from 'react';

type FormValues = Record<string, InputValue<any>>;

type FormState = {
  isValid: boolean;
  hasChanged: boolean;
  hasInteracted: boolean;
};

type Props = BaseComponentProps & {
  disableAutoFocus?: boolean;
  renderBody?(args: {
    Inputs(inputProps: InputsProps): ReactNode;
    inputProps: object;
    disabled: boolean;
    loading: boolean;
  }): ReactNode;
  formSpec: FormSpec;
  onSubmitSuccess?(): void;
  onFormChange?({
    formState,
    formValues,
    disabled,
  }: {
    formState: FormState;
    formValues: FormValues;
    disabled: boolean;
  }): void;
  InputsWrapperComponent?: () => ReactNode;
};

export type LivanFormRef = {
  reset(): void;
  submit(): void;
  validate(): void;
};

const LivanForm = React.memo(
  forwardRef(function LivanForm(props: Props, ref: ForwardedRef<LivanFormRef>) {
    useSignals();
    const {
      className,
      formSpec,
      renderBody,
      onFormChange,
      onSubmitSuccess,
      disableAutoFocus: disableAutofocus,
    } = props;
    const {onSubmit, submitText, inputSpecs, submitButtonFullWidth} = formSpec;
    const [loading, setLoading] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const formValuesSignal = useSignal<FormValues>({});
    const inputRefsByNameSignal = useSignal<Record<string, FormInputRef>>({});
    const params = useParams();
    const [searchParams] = useSearchParams();

    const getSetInputRef = useCallback(
      function (name: string) {
        return (ref: FormInputRef) => {
          inputRefsByNameSignal.value = {
            ...inputRefsByNameSignal.peek(),
            [name]: ref,
          };
        };
      },
      [inputRefsByNameSignal],
    );

    const formStateSignal = computed(() => {
      return Object.values(formValuesSignal.value).reduce<FormState>(
        (acc, formValue) => {
          const {errors, hasChanged, hasInteracted} = formValue;
          if (errors.length) {
            acc.isValid = false;
          }
          if (hasChanged) {
            acc.hasChanged = true;
          }
          if (hasInteracted) {
            acc.hasInteracted = true;
          }
          return acc;
        },
        {isValid: true, hasChanged: false, hasInteracted: false},
      );
    });

    const disabledSignal = computed(() => {
      return (
        !formStateSignal.value.isValid || (!!inputSpecs.length && !formStateSignal.value.hasChanged)
      );
    });

    const shouldBlock = useCallback(
      function ({currentLocation, nextLocation}) {
        return !!(
          formStateSignal.value.hasChanged &&
          !hasSubmitted &&
          currentLocation.pathname !== nextLocation.pathname
        );
      },
      [formStateSignal, hasSubmitted],
    );
    const blocker = useBlocker(shouldBlock);

    useEffect(() => {
      if (blocker.state === 'blocked') {
        openModal({
          title: 'Unsaved changes',
          content: 'You have unsaved changes. Would you like to discard them and proceed?',
          styleType: 'warning',
          formSpec: {
            submitText: 'Proceed',
            async onSubmit() {
              await requestAnimationFrameAsync();
              blocker.proceed();
            },
          },
          onCancelClick() {
            blocker.reset();
            batch(() => {
              Object.values(inputRefsByNameSignal.peek()).forEach((ref) => {
                ref.validate();
              });
            });
          },
        });
      }
    }, [blocker, formStateSignal, inputRefsByNameSignal]);

    const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
      async function (event) {
        stopPropagationAndPreventDefault(event);
        if (loading) {
          return;
        }
        const disabled = disabledSignal.value;
        if (disabled) {
          Object.values(inputRefsByNameSignal.peek()).forEach((ref) => {
            ref.validate();
          });
          return;
        }
        const values = Object.values(formValuesSignal.peek()).reduce((acc, {name, value}) => {
          acc[name] = value;
          return acc;
        }, {});
        setLoading(true);
        setHasSubmitted(true);
        let result;
        try {
          result = await onSubmit(values, {
            params,
            searchParams,
          });
          if (!result?.ignoreFormReset) {
            batch(() => {
              setHasSubmitted(false);
              formValuesSignal.value = {};
              Object.values(inputRefsByNameSignal.peek()).forEach((ref) => {
                ref.reset();
              });
            });
          }
          onSubmitSuccess?.();
        } catch (e) {
          const error = e as Error;
          const errorMeta = constructErrorMeta(error);
          displayClientError(errorMeta);

          assert(typeof errorMeta !== 'string', 'errorMeta is a string');
          if (errorMeta?.field && Object.entries(errorMeta?.field).length) {
            Object.entries(errorMeta.field).forEach(([name, errors]) => {
              if (errors) {
                assert(
                  formValuesSignal.value[name],
                  `form did not have field "${name}" but validator errored on it`,
                );
                formValuesSignal.value[name].errors = errors;
              }
            });
          }

          formValuesSignal.value = {
            ...formValuesSignal.value,
          };
        } finally {
          if (!result?.ignoreFormReset) {
            setLoading(false);
          }
        }
      },
      [
        loading,
        onSubmit,
        formValuesSignal,
        inputRefsByNameSignal,
        disabledSignal,
        params,
        searchParams,
        onSubmitSuccess,
      ],
    );

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLFormElement>>(
      function (event) {
        if (eventIsSubmit(event)) {
          return handleSubmit(event);
        }
      },
      [handleSubmit],
    );

    const handleInputChange = useCallback<OnInputChange<any>>(
      function (value) {
        const hasChanged = updateKeyOnSignalIfChanged(formValuesSignal, value.name, value);
        if (hasChanged) {
          onFormChange?.({
            formState: formStateSignal.value,
            formValues: formValuesSignal.value,
            disabled: disabledSignal.value,
          });
        }
      },
      [formValuesSignal, onFormChange, formStateSignal, disabledSignal],
    );

    const inputProps = useMemo(() => {
      return {
        inputSpecs,
        getSetInputRef: getSetInputRef,
        onInputChange: handleInputChange,
        formValuesSignal: formValuesSignal,
        hasSubmitted,
      };
    }, [inputSpecs, getSetInputRef, handleInputChange, formValuesSignal, hasSubmitted]);

    useImperativeHandle(ref, () => {
      return {
        reset() {
          batch(() => {
            Object.values(inputRefsByNameSignal.peek()).forEach((ref) => {
              ref.reset();
            });
          });
        },
        submit() {
          handleSubmit(new Event('submit') as unknown as React.FormEvent<HTMLFormElement>);
        },
        validate() {
          batch(() => {
            Object.values(inputRefsByNameSignal.peek()).forEach((ref) => {
              ref.validate();
            });
          });
        },
      };
    });

    return (
      <form
        className={clsx(className, 'flex flex-col')}
        onKeyDown={handleKeyDown}
        onSubmit={handleSubmit}
        noValidate
      >
        {renderBody ? (
          renderBody({
            Inputs,
            inputProps,
            disabled: disabledSignal.value,
            loading,
          })
        ) : (
          <div className="flex flex-col gap-2">
            <Inputs
              {...inputProps}
              disableAutofocus={disableAutofocus}
            />
            <div>
              <Button
                type="submit"
                fullWidth={submitButtonFullWidth}
                disabled={disabledSignal.value}
                loading={loading}
              >
                {submitText || 'Submit'}
              </Button>
            </div>
          </div>
        )}
      </form>
    );
  }),
);

type InputsProps = {
  inputSpecs: InputSpec<any>[];
  disableAutofocus?: boolean;
  getSetInputRef(name: string): RefCallback<FormInputRef>;
  onInputChange: OnInputChange<any>;
  formValuesSignal: Signal<Record<string, InputValue<any>>>;
  hasSubmitted: boolean;
};

function Inputs(props: InputsProps) {
  useSignals();
  const {
    inputSpecs,
    onInputChange,
    getSetInputRef,
    formValuesSignal,
    hasSubmitted,
    disableAutofocus,
  } = props;
  return inputSpecs.map((inputSpec, i) => {
    const {type, name, ...inputSpecRest} = inputSpec;
    const InputComponent = InputComponentsByType[type];
    const setInputRef = getSetInputRef(name);
    const errors =
      hasSubmitted || formValuesSignal.value[name]?.hasInteracted
        ? formValuesSignal.value[name]?.errors
        : [];

    return (
      // @ts-expect-error not sure how to fix this with dynamic input `type`s
      <InputComponent
        {...inputSpecRest}
        type={type}
        autoFocus={i === 0 && !disableAutofocus}
        ref={setInputRef}
        name={name}
        key={name}
        onChange={onInputChange}
        errors={errors}
      />
    );
  });
}

export default LivanForm;
