import {valtioProxy} from '@/utils/valtio';

export type Breakpoint = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const initialWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
const initialHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

export const viewportDimensionsProxy = valtioProxy({
  width: initialWidth,
  height: initialHeight,
  get currentBreakpoint(): Breakpoint | undefined {
    if (this.xs) return 'xs';
    if (this.sm) return 'sm';
    if (this.md) return 'md';
    if (this.lg) return 'lg';
    if (this.xl) return 'xl';
    return 'none';
  },
  get xs() {
    return this.width >= 375;
  },
  get sm() {
    return this.width >= 640;
  },
  get md() {
    return this.width >= 768;
  },
  get lg() {
    return this.width >= 1024;
  },
  get xl() {
    return this.width >= 1280;
  },
});

if (typeof window !== 'undefined') {
  window.addEventListener('resize', () => {
    viewportDimensionsProxy.value.width = window.innerWidth;
    viewportDimensionsProxy.value.height = window.innerHeight;
  });

  window.addEventListener('orientationchange', () => {
    viewportDimensionsProxy.value.width = window.innerWidth;
    viewportDimensionsProxy.value.height = window.innerHeight;
  });
}
