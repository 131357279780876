import type {OnInputChange} from '@/components/form/FormTypes';
import {useEffectOnce} from '@/utils/react';
import React, {useEffect} from 'react';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type ChangeEventHandler,
  type FocusEventHandler,
  type Ref,
  type RefObject,
} from 'react';

import {type ForwardedRef, type ForwardRefRenderFunction} from 'react';
import type {ZodTypeAny} from 'zod';

type FormInputProps<V> = {
  name: HTMLInputElement['name'];
  label?: string;
  defaultValue?: V;
  onChange: OnInputChange<V>;
  validator?: ZodTypeAny;
  required?: HTMLInputElement['required'];
  errors: string[] | undefined;
};

type OnChange<V> = (args: {value: V; hasInteracted: boolean}) => void;

type ComponentProps<V> = Omit<FormInputProps<V>, 'onChange' | 'errors'> & {
  onChange: OnChange<V>;
  onInputRef: Ref<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  errors: string[];
  value: V;
};

export type FormInputRef = {
  focus(): void;
  blur(): void;
  reset(): void;
  validate(): void;
};

export default function FormInput<P, V>({
  Component,
  defaultValue: inputDefaultValue,
}: {
  Component: ForwardRefRenderFunction<FormInputRef, P & ComponentProps<V>>;
  defaultValue: V;
}) {
  return React.memo(
    forwardRef(function FormInputWrapper(
      props: P & FormInputProps<V>,
      ref: ForwardedRef<FormInputRef>,
    ) {
      const inputRef = useRef<HTMLInputElement>(null);

      const {
        name,
        defaultValue = inputDefaultValue,
        validator,
        onChange,
        errors,
        required,
        label,
      } = props;

      const [value, setValue] = useState<V>(defaultValue);

      useEffect(() => {
        setValue(defaultValue);
      }, [defaultValue]);

      useImperativeHandle(ref, () => {
        return {
          focus() {
            inputRef.current?.focus();
          },
          blur() {
            inputRef.current?.blur();
          },
          reset() {
            const value = defaultValue;
            setValue(() => value);
            const errors = validate(value);
            onChange?.({
              name,
              value,
              hasChanged: false,
              errors,
              hasInteracted: false,
            });
          },
          validate() {
            const errors = validate(value);
            onChange?.({
              name,
              value,
              hasChanged: false,
              errors,
              hasInteracted: true,
            });
          },
        };
      });

      const hasChanged = useMemo(() => {
        return defaultValue !== value;
      }, [defaultValue, value]);

      const validate = useCallback(
        function (value: V) {
          const errors: string[] = [];
          if (validator) {
            const result = validator.safeParse(value);
            if (result?.error) {
              errors.push(...result.error.issues.map(({message}) => message));
            }
            if (result?.data != null) {
              value = result.data;
            }
          }

          if (!errors.length && typeof value === 'string' && value.length <= 0 && required) {
            errors.push(`${label || 'Field'} is required`);
          }
          return errors;
        },
        [label, required, validator],
      );

      const handleChange = useCallback<OnChange<V>>(
        function ({value, hasInteracted = true}) {
          const errors = validate(value);
          setValue(() => value);
          const hasChanged = defaultValue !== value; // must compute this out of band since we won't get a re-render to update the `value`

          onChange?.({
            name,
            value,
            hasChanged,
            hasInteracted,
            errors,
          });
        },
        [onChange, name, validate, defaultValue],
      );

      const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
        function (event) {
          if ((event.relatedTarget as any)?.href && event.relatedTarget?.role !== 'button') {
            // if we're navigating away to a tag with an href (likely an anchor tag), don't let validation kick in because it causes UI jank
            return;
          }
          const errors = validate(value);
          onChange?.({
            name,
            value,
            hasChanged,
            hasInteracted: true,
            errors,
          });
        },
        [onChange, hasChanged, name, validate, value],
      );

      useEffectOnce(() => {
        const value = defaultValue;
        const errors = validate(value);
        onChange?.({
          name,
          value,
          hasChanged: false,
          hasInteracted: false,
          errors,
        });
      });

      return (
        <div className="FormInput">
          {Component(
            {
              ...props,
              onInputRef: inputRef,
              onChange: handleChange,
              onBlur: handleBlur,
              errors: errors || [],
              value,
            },
            ref,
          )}
        </div>
      );
    }),
  );
}
