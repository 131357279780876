import type {AnimationPhase} from '@/components/core/Animation';
import Button from '@/components/core/Button';
import LivanLink from '@/components/core/LivanLink';
import type {FormSpec} from '@/components/form/FormTypes';
import LivanForm from '@/components/form/LivanForm';
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react';
import {InformationCircleIcon} from '@heroicons/react/20/solid';
import {ExclamationTriangleIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';
import React from 'react';
import {useCallback, type MouseEventHandler, type ReactNode} from 'react';

type ModalConfig = {
  titleClassName: string;
  iconProps: {
    className: string;
  };
  iconWrapperProps: {
    className: string;
  };
};

const configsByStyleType: Record<StyleType, ModalConfig> = {
  default: {
    titleClassName: 'bg-livan-black text-white',
    iconProps: {
      className: 'text-green-600',
    },
    iconWrapperProps: {
      className: 'bg-green-100',
    },
  },
  warning: {
    titleClassName: 'bg-livan-black text-white',
    iconProps: {
      className: 'text-yellow-600',
    },
    iconWrapperProps: {
      className: 'bg-yellow-100',
    },
  },
  destructive: {
    titleClassName: 'bg-livan-black text-white',
    iconProps: {
      className: 'text-red-600',
    },
    iconWrapperProps: {
      className: 'bg-red-100',
    },
  },
};

export type ModalProps = {
  title: string;
  content?: ReactNode | (() => ReactNode);
  styleType?: StyleType;
  className?: string;
  onCancelClick?: () => void;
  formSpec: FormSpec;
  cancelButtonText?: string;
  animationPhase: AnimationPhase;
  hideButtons?: boolean;
  loading?: boolean;
};

export type ModalType = keyof typeof configsByStyleType;

const Modal = React.memo(function Modal(props: ModalProps) {
  useSignals();
  const {
    className,
    styleType = 'default',
    title,
    onCancelClick,
    cancelButtonText,
    animationPhase,
    loading,
    formSpec,
    hideButtons = false,
  } = props;
  let {content} = props;
  if (typeof content === 'function') {
    content = content();
  }

  const handleCancelClick = useCallback(
    function () {
      onCancelClick?.();
    },
    [onCancelClick],
  );
  const onDialogClose = useCallback(
    function () {
      if (loading) {
        return;
      }
      onCancelClick?.();
    },
    [loading, onCancelClick],
  );

  return (
    <Dialog
      open
      role="dialog"
      onClose={onDialogClose}
      className={clsx('Modal', 'relative z-40', className)}
    >
      <DialogBackdrop
        transition
        className={clsx(
          'fixed inset-0 bg-livan-black/75',
          animationPhase && `Modal-animating-${animationPhase}`,
          animationPhase === 'in' && 'animate-fade-in',
          animationPhase === 'out' && 'animate-fade-out',
        )}
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={clsx(
              'relative rounded-lg bg-white text-left shadow-xl sm:my-8 w-full sm:max-w-lg',
              animationPhase && `DialogPanel-animating-${animationPhase}`,
              animationPhase === 'in' && 'animate-fade-in',
              animationPhase === 'out' && 'animate-fade-out',
            )}
          >
            <ModalBodyDefault
              styleType={styleType}
              hideButtons={hideButtons}
              formSpec={formSpec}
              onCancelClick={onCancelClick && handleCancelClick}
              loading={loading}
              title={title}
              content={content}
              cancelButtonText={cancelButtonText}
            />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
});

export default Modal;

type ModalBodyDefaultProps = {
  title: string;
  styleType?: StyleType;
  content: ReactNode | undefined;
  formSpec: FormSpec;
  onCancelClick?: () => void;
  cancelButtonText?: string;
  loading?: boolean;
  hideButtons?: boolean;
};

const modalBodyDefaultSectionPadding = 'py-2 sm:py-3 px-3 sm:px-4';

const ModalBodyDefault = React.memo(function ModalBodyDefault(props: ModalBodyDefaultProps) {
  useSignals();
  const {
    styleType = 'default',
    title,
    onCancelClick,
    cancelButtonText = 'Cancel',
    loading = false,
    content,
    formSpec,
    hideButtons = false,
  } = props;

  const {iconProps, iconWrapperProps, titleClassName} = configsByStyleType[styleType];

  const renderFormBody = useCallback(
    function ({Inputs, inputProps, disabled, loading}) {
      return (
        <React.Fragment>
          <div
            className={clsx(
              'bg-livan-black flex gap-2 items-center rounded-t-lg',
              titleClassName,
              modalBodyDefaultSectionPadding,
            )}
          >
            <DialogTitle
              as="h3"
              className="flex-1"
            >
              {title}
            </DialogTitle>
            {onCancelClick && (
              <LivanLink
                role="button"
                type="link-light"
                className="shrink-0 size-6"
                onClick={onCancelClick}
              >
                <XMarkIcon />
                <span className="sr-only">Close</span>
              </LivanLink>
            )}
          </div>
          <div className={clsx(modalBodyDefaultSectionPadding, hideButtons && 'rounded-b-lg')}>
            {content && <div>{content}</div>}
            <Inputs {...inputProps} />
          </div>
          {!hideButtons && (
            <div
              className={clsx(
                'bg-gray-100 flex gap-2 justify-end rounded-b-lg',
                modalBodyDefaultSectionPadding,
              )}
            >
              {onCancelClick && (
                <Button
                  disabled={loading}
                  flavor="link"
                  onClick={onCancelClick}
                >
                  {cancelButtonText}
                </Button>
              )}
              <Button
                type="submit"
                loading={loading}
                disabled={disabled}
                styleType={styleType}
              >
                {formSpec.submitText || 'Confirm'}
              </Button>
            </div>
          )}
        </React.Fragment>
      );
    },
    [
      title,
      content,
      cancelButtonText,
      onCancelClick,
      styleType,
      formSpec.submitText,
      hideButtons,
      titleClassName,
    ],
  );

  return (
    <LivanForm
      formSpec={formSpec}
      renderBody={renderFormBody}
    />
  );
});
